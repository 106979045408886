import React, {useRef, useState, useEffect} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {Notification, toast} from 'components/ui';
const TinyMCE = (props, ref) => {
  const {
    value,
    height = 500,
    language = 'es',
    menubar = false,
    onChange = false,
    filesName = 'files',
    videosName = 'videoFiles',
    form,
    field,
    plugins = [
      'emoticons',
      'lists',
      'link',
      'image',
      'preview',
      'code',
      'fullscreen',
      'media',
      'table',
      'help'
    ],
    toolbar = `
      undo redo | 
      blocks |
      media_upload |
      table |
      bold italic backcolor forecolor| 
      alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent | 
      removeformat |
      fullscreen help |
      code
    `
  } = props;
  const editorRef = useRef(null);

  return (
    <>
      <Editor
        apiKey="txo86q52w7gelqvuxsrk3ky6humwqc92b51rv2w4u6e9ihrp"
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={content => {
          form.setFieldValue(field.name, content);
        }}
        init={{
          height: height,
          menubar: menubar,
          language: language,
          plugins: plugins,
          toolbar: toolbar,
          //paste_data_images: false,
          //automatic_uploads: false,
          setup: editor => {
            editor.ui.registry.addButton('media_upload', {
              text: '',
              icon: 'gallery',
              tooltip: 'Agregar Imagen/Video',
              onAction: () => {
                editor.windowManager.open({
                  title: 'Insertar Imagen/Video',
                  body: {
                    type: 'panel',
                    items: [
                      {
                        type: 'htmlpanel',
                        html: `
                          <div style="padding-top: 20px; text-align: center; background-color: #f9f9f9; border-radius: 8px; padding: 20px; 
                                      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); border: 2px dashed #007bff;">
                              <label for="tiny-file-upload" style="display: block; padding: 10px 20px; background-color: #007bff; color: white; 
                                      border-radius: 5px; cursor: pointer; font-size: 16px; font-weight: 500;">Seleccionar archivo</label>
                              <input onChange="document.getElementById('label_nombre_upload').innerHTML=this?.files?.[0]?.name;" 
                                    type="file" style="display: none;" accept="video/mp4, image/jpeg, image/jpg, image/png" id="tiny-file-upload">
                              <div style="margin-bottom: 20px;">
                                  <label id="label_nombre_upload" style="font-size: 16px; font-weight: 500; color: #555;"></label>
                              </div>
                              <div style="margin-top: 10px; color: #555; font-size: 14px; opacity: 0.7;">
                                  Extensiones permitidas: <b>mp4, jpg, jpeg, png</b>
                              </div>
                          </div>
`
                      }
                    ]
                  },
                  buttons: [
                    {
                      type: 'cancel',
                      text: 'Cancelar'
                    },
                    {
                      type: 'submit',
                      text: 'Agregar',
                      primary: true
                    }
                  ],

                  onSubmit: api => {
                    const fileInput =
                      document.getElementById('tiny-file-upload');
                    const file = fileInput?.files?.[0];
                    if (!file) {
                      return alert(
                        'Es necesario agregar un archivo con formato valido'
                      );
                    }
                    const blobMedia = URL.createObjectURL(file);

                    if (file?.type === 'video/mp4') {
                      editor.insertContent(
                        `<div>
                          <video width="320" height="240" controls>
                            <source src="${blobMedia}" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>`
                      );
                    } else {
                      editor.insertContent(
                        `<img width="320" height="240" src="${blobMedia}" alt="" style="max-width:100%;">`
                      );
                    }

                    api.close();
                  }
                });
              }
            });
            editor.on('change', () => {
              const content = editorRef.current.getBody().innerHTML;

              // Obtener imágenes y videos
              const images = editor
                .getBody()
                .querySelectorAll('img[src^="blob:"]');
              const videos = editor
                .getBody()
                .querySelectorAll('video source[src^="blob:"]');

              const mediaUrls = new Set(
                [...images, ...videos].map(media => media.src)
              );

              form.setFieldValue(field.name, content);
              form.setFieldValue(filesName, mediaUrls);
              //form.setFieldValue(videosName, videoUrls);
            });
          }
        }}
      />
    </>
  );
};

export default TinyMCE;
