import React from "react";
import Formatear from "./Formatear";
import { Dropdown, Skeleton } from "components/ui";
import DloreanButtonsReload from "./DloreanButtonsReload";
import { MediaSkeleton, Loading, IconText } from "components/shared";
import { AiFillCaretDown } from "react-icons/ai";
import { useDlorean } from "store/DLOREAN/dloreanStore";
import { misRutas } from "configs/routes.config";

const Breadcrumb = React.forwardRef((props, ref) => {
  const { coreDlorean, dispatchDlorean } = useDlorean();

  const pathname = window.location.pathname.split('/');
  const key_route = `${pathname[1]}-${pathname[2]}-${pathname[3]}`;
  const route_layout_type_blank = misRutas.filter(ruta => ruta.key === key_route)
  if (route_layout_type_blank.length > 0) {
    return (<></>)
  }

  return (
    <>
      <div className="py-2 bg-white dark:bg-gray-800 ">
        {/* ESCRITORIO */}
        {/* Se agrega un div padre para juntar del breadcrumb y el boton y tengan la misma altura */}
        <div className="pl-8 justify-between items-center py-2 print:hidden hidden md:flex">
          {/* Se desactiva el flex para que el titulo y el breadcrumb no queden en la misma linea */}

          <Loading
            loading={!coreDlorean.RELOAD_BREADCRUMB?.valores}
            customLoader={
              <MediaSkeleton
                titleProps={{
                  width: 160,
                }}
                textProps={{
                  width: 170,
                }}
                avatarProps={{
                  width: 0,
                  height: 0,
                }}
              />
            }
          >
            <div className="">
              {" "}
              {/* Se cambiara en un futuro */}
              {/* Se coloca el nombre del módulo */}
              <h4 className="">{coreDlorean.RELOAD_NOMBRE_MODULO || "Cargando..."}</h4>
              <div className="md:flex hidden">
                <div className="flex gap-2 mt-1 text-sm">
                  {coreDlorean.RELOAD_BREADCRUMB?.valores &&
                    coreDlorean.RELOAD_BREADCRUMB?.valores.map((val, i) => (
                      <Formatear
                        key={i}
                        movil={false}
                        valores={val}
                        tipo={typeof coreDlorean.RELOAD_BREADCRUMB?.valores[i + 1]}
                      ></Formatear>
                    ))}
                </div>
              </div>
            </div>
          </Loading>

          <div className="mr-8">
            {/* aqui va el botón de la derecha, aun falta llamarlo header el componente principal */}
            <DloreanButtonsReload></DloreanButtonsReload>
          </div>
        </div>

        {/* CELULAR */}
        <div className="mr-0 md:hidden">
          <h4 className="[font-size:20px] md:hidden text-center py-2">{coreDlorean.RELOAD_SHOW ? coreDlorean.RELOAD_NOMBRE_MODULO : <div className="flex justify-center items-center"><Skeleton height={25} width={'60%'} /></div>}</h4>

          <div className="my-2">
            <hr />
          </div>

          <div className="flex justify-between items-center mt-2 mx-4">

            <div className="">
              <Dropdown
                className=""
                renderTitle={
                  <div className="flex [font-size:16px] text-blue-500">
                    <AiFillCaretDown className="my-auto"></AiFillCaretDown>../
                    <IconText textClass="text-sm font-semibold" className="text-blue-500"></IconText>
                  </div>
                }
              >
                {coreDlorean.RELOAD_BREADCRUMB?.valores &&
                  coreDlorean.RELOAD_BREADCRUMB?.valores.map((val, i) => (
                    <Dropdown.Item eventKey={val.titulo} key={i} className="[font-size:13px]">
                      <Formatear
                        isMovil={true}
                        valores={val}
                        tipo={typeof coreDlorean.RELOAD_BREADCRUMB?.valores[i + 1]}
                      ></Formatear>
                    </Dropdown.Item>
                  ))}
              </Dropdown>
            </div>

            <DloreanButtonsReload isMobile={true}></DloreanButtonsReload>
          </div>
        </div>

      </div>
    </>
  );
});

export default Breadcrumb;
