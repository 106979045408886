import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect } from 'react-table'
import { Table, Pagination, Select, Tooltip, Input, Checkbox, Menu } from '../../ui'
import { matchSorter } from 'match-sorter'

const { Tr, Th, Td, THead, TBody, Sorter } = Table
const pageSizeOption = [
	{ value: 10, label: '10 / Página' },
	{ value: 25, label: '25 / Página' },
	{ value: 50, label: '50 / Página' },
	{ value: 100, label: '100 / Página' },
]

/* ********  FUNCION PARA LOS AGREGAR CHECKBOX ********  */
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, onChange, ...rest }, ref) => {
	const defaultRef = useRef()
	const resolvedRef = ref || defaultRef

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])

	return <Checkbox ref={resolvedRef} onChange={(_, e) => onChange(e)} {...rest} />
})


/* ********  FUNCION PARA LOS FILTROS ********  */
function FilterInput({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
	const count = preGlobalFilteredRows.length
	const [value, setValue] = useState(globalFilter)
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined)
	}, 200)

	return (
		<>
			<div className="flex justify-start">
				<div className="flex items-center my-3">
					<span className="mr-2">Buscar:</span>
					<Input
						size="sm"
						value={value || ""}
						onChange={e => {
							setValue(e.target.value)
							onChange(e.target.value)
						}}
						style={{ maxWidth: 180 }}
						placeholder={`${count} registros...`}
					/>
				</div>
			</div>
		</>
	)
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

//Remueve el filtro cuando el valor sea vacío
fuzzyTextFilterFn.autoRemove = val => !val


/* ******** CREACIÓN DE LA TABLA ********** */
const ReactTable = props => {
	const { columns, data, dataLength, tChecked } = props
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);
	const [isVisible, setIsVisible] = useState(false);
	const [idPrimary, setIdPrimary] = useState({})
	/* EVENTOS DE CLICK, CLICK DERECHO, TOUCH, MANTENER EL CLICK, ETC.. */
	const handleClick = (e, ...rows) => {
		switch (e.type) {
			case 'click': setIsVisible(false);
				break;
			case 'contextmenu': e.preventDefault()
				setX(e.clientX)
				setY(e.clientY);
				setIsVisible(true);
				/* setIdPrimary(rows[0]); */
				console.log(rows[0].values);


				break;
			default: break
		}
	};

	/* FILTROS */
	const filterTypes = useMemo(() => ({
		fuzzyText: fuzzyTextFilterFn,
		text: (rows, id, filterValue) => {
			return rows.filter(row => {
				const rowValue = row.values[id]
				return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true
			})
		},
	}), [])
	/* FILTROS */

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		selectedFlatRows,
		page,
		gotoPage,
		setPageSize,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: false,
			filterTypes
		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			tChecked &&
				hooks.visibleColumns.push(columns => [
					// Let's make a column for selection
					{
						id: 'selection',
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							</div>
						),
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				])
		}
	)

	const onPaginationChange = page => {
		gotoPage(page - 1)
	}

	const onSelectChange = value => {
		setPageSize(Number(value))
	}



	return (
		<>
			<div className={`${isVisible ? 'block' : 'hidden'} fixed z-50`} style={{ top: y, left: x }}>
				<Menu variant={'light'} style={{ maxWidth: 250 }} className="rounded-md border">
					<Menu.MenuItem eventKey="settings">Editar</Menu.MenuItem>
					<Menu.MenuItem eventKey="message">Autorizar</Menu.MenuItem>
					<Menu.MenuItem eventKey="gallery">Detalles</Menu.MenuItem>
				</Menu>
			</div>

			<div className=''>
				<div className=' border-[1px] border-gray-100 shadow-md rounded-b-lg rounded-t-lg bg-white p-4' >
					<FilterInput
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
					<Table {...getTableProps()} className="">
						<THead >
							{headerGroups.map((headerGroup, i) => (
								<Tr key={i} {...headerGroup.getHeaderGroupProps()} className="rounded-t-md">
									{headerGroup.headers.map((column, i) => (
										<>
											{/* Verificamos que la columna tenga filtro */}
											{typeof column.filtro === 'undefined'
												?
												/* Si no tiene filtro colocaremos el TH sin la funcion de getSortBy */
												<Th {...column.getHeaderProps()} key={i} className="">
													{
														/* Verificaremso si contiene el dato del tooltip, en caso de que no lo contenga las columnas no se mostrará */
														typeof column.tooltip === 'undefined'
															? <><strong className="">{column.render('Header')}</strong></>
															: <Tooltip title={<strong className="text-yellow-400">{column.tooltip}</strong>}>
																{column.render('Header')}
															</Tooltip>
													}
												</Th>

												:
												/* Este TH si contiene filtro, asi que se crearan las columnas con la función del SORT */
												<Th {...column.getHeaderProps(column.getSortByToggleProps())} key={i} className="">
													{
														/* Verificaremso si contiene el dato del tooltip, en caso de que no lo contenga las columnas no se mostrará */
														typeof column.tooltip === 'undefined'
															? <><strong className="">{column.render('Header')}<span><Sorter sort={column.isSortedDesc} /></span></strong></>
															: <Tooltip title={<strong className="text-yellow-400">{column.tooltip}</strong>}>
																{column.render('Header')}
																<span><Sorter sort={column.isSortedDesc} /></span>
															</Tooltip>
													}
												</Th>
											}


										</>
									))}
								</Tr>
							))}
						</THead>
						<TBody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<Tr key={i} onClick={handleClick} onContextMenuCapture={(e) => handleClick(e, row)} {...row.getRowProps()}>
										{row.cells.map(cell => {
											return (
												<Td {...cell.getCellProps()}>
													{cell.render('Cell')}
												</Td>
											)
										})}
									</Tr>
								)
							})}
						</TBody>
					</Table>
					<div className="flex items-center justify-between m-4">
						<Pagination
							pageSize={pageSize}
							currentPage={pageIndex + 1}
							total={dataLength}
							onChange={onPaginationChange}
						/>
						<div style={{ minWidth: 130 }}>
							<Select
								isClearable={false}
								size="sm"
								isSearchable={false}
								value={pageSizeOption.filter(option => option.value === pageSize)}
								options={pageSizeOption}
								onChange={option => onSelectChange(option.value)}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


export default function Tabla({ columnas = null, datos = null, checked = false }) {

	const columnasMemo = useMemo(() => columnas, [columnas])

	return (<ReactTable data={datos} columns={columnasMemo} dataLength={datos.length || []} tChecked={checked} />)
}

