import ApiService from "services/ApiService";

export async function CoreGuardar(data, conf) {
  return ApiService.fetchData({
    url: `/${conf.sk_proyecto}/${conf.sk_subproyecto}/${conf.sk_modulo}/${conf.s_slug}/guardar/${conf.paramsUrl}`,
    method: "post",
    data,
  });
}

export async function CoreGuardarExterno(data, conf) {
  return ApiService.fetchData({
    url: `/${conf.sk_proyecto}/${conf.sk_subproyecto}/${conf.sk_modulo}/${conf.s_slug}/guardar/${conf.paramsUrl}`,
    method: "post",
    data,
  });
}
