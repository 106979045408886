import { Link } from "react-router-dom";

export default function Formatear({ valores, tipo, isMovil, onClose = () => { } }) {
  const computadoras = () => {
    return (
      //undefined = que es la ultima posición del JSON
      tipo === "undefined"
        ? (valores.titulo)
        :
        valores.url !== ""
          ?

          valores.navegacion
            ? ((
              //Existe URL así que se pintará de azul y tendra un HREF
              <Link to={valores.url} onClick={onClose}>
                <b className="text-blue-500">{valores.titulo.trim()} </b> /
              </Link>
            ))
            : <span className=""> <b>{valores.titulo.trim()}</b> / </span>

          : (
            //No existe URL así que no será un <a></a>
            <span className="text-gray-400"> {valores.titulo.trim()} / </span>
          )
    );
  };

  const celulares = () => {
    return (
      //undefined = que es la ultima posición del JSON
      tipo === "undefined" ? (
        valores.titulo
      ) : valores.url !== "" ? (
        //Existe URL así que se pintará de azul y tendra un HREF
        valores.navegacion
          ? ((
            //Existe URL así que se pintará de azul y tendra un HREF
            <Link to={valores.url} onClick={onClose}>
              <b className="text-blue-500">{valores.titulo.trim()} </b> 
            </Link>
          ))
          : <span className=""> <b>{valores.titulo.trim()}</b> </span>

      ) : (
        //No existe URL así que no será un <a></a>
        <span className="text-gray-300"> {valores.titulo.trim()}</span>
      )
    );
  };

  return <div>{isMovil ? celulares() : computadoras()}</div>;
}
