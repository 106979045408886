import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./dlorean.css";
import App from "./App";

if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {})
      .catch((error) => {});
  }
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
    } else {
    }
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
