import {useSelector, useDispatch} from 'react-redux';
import {setUser, initialState} from 'store/auth/userSlice';
import {
  apiSignIn,
  apiSignOut,
  apiSignUp,
  apiConfigurarInicio,
  api_obtenerImagen,
  apiConfigurarCambioSucursal
} from 'services/AuthService';
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken
} from 'store/auth/sessionSlice';
import {PERSIST_STORE_NAME, APP_HOST_FRONTEND} from 'constants/app.constant';
import appConfig from 'configs/app.config';
import {REDIRECT_URL_KEY} from 'constants/app.constant';
import {useNavigate} from 'react-router-dom';
import useQuery from './useQuery';
import {useMyContext} from '../../provider/Provider';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const {token, signedIn} = useSelector(state => state.auth.session);

  const [state, setState] = useMyContext();

  const signIn = async values => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        return resp.data;
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const configurarInicio = async ({datosEmpresa, usuario}) => {
    try {
      const resp = await apiConfigurarInicio(datosEmpresa);
      const {sk_token, menu} = resp.data.data;

      const resApiImg = await api_obtenerImagen({
        sk_usuario: usuario?.sk_usuario
      });
      const resImg = resApiImg.data.data;
      let blob_imagen_perfil = false;
      if (resImg.img !== '') {
        const img = new Uint8Array(resImg.img.data);
        const blob = new Blob([img], {type: resImg.s_content_type});
        blob_imagen_perfil = URL.createObjectURL(blob);
      }

      if (resp.data) {
        if (usuario) {
          /* AQUI GUARDAREMOS EL ESTADO Y EL LOCALSTORAGE CON NUESTROS DATOS */
          //const util_guardarEstado = await Util_guardarEstado(resp.data.usuario)

          delete usuario.s_password;

          const objetoStorage = {
            usuario: {
              ...usuario,
              s_empresa: datosEmpresa.s_empresa,
              s_sucursal: datosEmpresa?.s_sucursal,
              s_nombre_grupo_socio_comercial:
                datosEmpresa?.s_nombre_grupo_socio_comercial
            },
            menu: menu,
            sucursales: datosEmpresa?.sucursales,
            blob_s_foto: blob_imagen_perfil
          };

          setState(objetoStorage);
          localStorage.setItem('datos', JSON.stringify(objetoStorage));

          /* *************************************************************** */
          dispatch(onSignInSuccess(sk_token));
          dispatch(
            setUser(
              resp.data.usuario || {
                sAvatar: '',
                s_nombre: 'Anonymous',
                authority: ['USER'],
                s_correo: '',
                s_usuario: '',
                sk_usuario: ''
              }
            )
          );

          const redirectUrl = query.get(REDIRECT_URL_KEY);
          navigate(
            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
          );
          return {
            status: 'success',
            message: ''
          };
        }
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const cambiarSucursal = async ({datosEmpresa}) => {
    try {
      const resp = await apiConfigurarCambioSucursal(datosEmpresa);

      const {sk_token, menu} = resp.data.data.data;

      const resApiImg = await api_obtenerImagen({
        sk_usuario: datosEmpresa?.sk_usuario
      });
      const resImg = resApiImg.data.data;
      let blob_imagen_perfil = false;
      if (resImg.img !== '') {
        const img = new Uint8Array(resImg.img.data);
        const blob = new Blob([img], {type: resImg.s_content_type});
        blob_imagen_perfil = URL.createObjectURL(blob);
      }

      if (resp.data) {
        if (datosEmpresa) {
          /* AQUI GUARDAREMOS EL ESTADO Y EL LOCALSTORAGE CON NUESTROS DATOS */
          //const util_guardarEstado = await Util_guardarEstado(resp.data.usuario)

          const objetoStorage = {
            usuario: {
              ...datosEmpresa,
              s_empresa: datosEmpresa.s_empresa,
              s_sucursal: datosEmpresa?.s_sucursal
            },
            menu: menu,
            sucursales: datosEmpresa?.sucursales,
            blob_s_foto: blob_imagen_perfil
          };

          setState(objetoStorage);
          localStorage.setItem('datos', JSON.stringify(objetoStorage));

          let authData = JSON.parse(localStorage.getItem('portal'));
          let auth = JSON.parse(authData.auth);

          auth.session.token = sk_token;
          authData.auth = JSON.stringify(auth);

          localStorage.setItem('portal', JSON.stringify(authData));
          /* *************************************************************** */

          //dispatch(setToken(sk_token));

          window.location.reload();

          return {
            status: 'success',
            message: ''
          };
        }
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const signUp = async values => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const {token} = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                sAvatar: '',
                s_nombre: 'Anonymous',
                authority: ['USER'],
                s_correo: '',
                s_usuario: '',
                sk_usuario: ''
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: ''
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString()
      };
    }
  };

  const handleSignOut = async () => {
    /* 		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState)) */
    //navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async (conf = {}) => {
    localStorage.removeItem('datos');
    localStorage.removeItem(PERSIST_STORE_NAME);

    if (conf?.s_url) {
      window.location.href = `${appConfig.websiteUrl}${appConfig.unAuthenticatedEntryPath}?redirectUrl=${conf.s_url}`;
    } else {
      window.location.href = `${appConfig.websiteUrl}${appConfig.unAuthenticatedEntryPath}`;
    }

    /* 		 const portal = '{"auth":"{\\"session\\":{\\"token\\":\\"\\",\\"signedIn\\":false},\\"user\\":{\\"sAvatar\\":\\"\\",\\"s_nombre\\":\\"Anonymous\\",\\"authority\\":[\\"USER\\"],\\"s_correo\\":\\"\\",\\"s_usuario\\":\\"\\",\\"sk_usuario\\":\\"\\"}}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}';
		localStorage.setItem(PERSIST_STORE_NAME, portal) */
    //await apiSignOut()

    //await handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    configurarInicio,
    signIn,
    signUp,
    signOut,
    cambiarSucursal
  };
}

export default useAuth;
